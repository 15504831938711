<template>
  <div class="space-y-5">    
      <div>
        <h1 class="text-2xl font-medium">
          {{ dashboard_summary_title }}
        </h1>
      </div>
      <div
        class="h-40 flex flex-row pt-4 space-x-4 items-center justify-center"
        v-if="errorMessage != null"
      >
        <p class="text-error">{{ errorMessage }}</p>
        <app-button-outline
          class="h-10 space-x-2 w-auto justify-between"
          @click="getSummary"
        >
          <div
            class="
              place-items-start
              flex flex-col
              text-gray-700
              font-semibold
              antialiased
              text-xs
            "
          >
            <span class="text-gray-700">
              {{ $t("general.try_again") }}
            </span>
          </div>
        </app-button-outline>
      </div>
      <div
      class="
        py-3
        flex flex-col
        justify-between
        md:flex-row md:space-y-0 md:space-x-3
        space-y-3 space-x-0
      "
      v-if="errorMessage == null"
    >
      <p v-if="this.select_date_type == this.$t('general.daily')" class="border p-2 rounded-md">
        {{ getFromDate() }} 
      </p>
      <p v-else class="border p-2 rounded-md">
        {{ getFromDate() }} {{ $t("general.to") }} {{ getToDate() }}
      </p>
      <app-button-select-date
        @select_date="select_date = $event"
        @select_date_format="select_date_format = $event"
        @select_date_type="select_date_type = $event"
        @on_select="getSummary"
      />
    </div>
    <div
      class="grid grid-rows-1 md:grid-cols-4 gap-3 mt-3 text-gray-600"
      v-if="errorMessage == null"
    >
      <app-card-box class="space-y-1 col-span-2 h-40">
        <p class="text-lg font-medium">
          {{ $t("dashboard.account.total_collection") }}
        </p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          class="w-32"
          :random-size="true"
        />
        <p class="text-2xl" v-if="!loading">
          {{
            $formats.currency(
              summary.currency,
              summary.total_collection?.amount ?? 0
            )
          }}
        </p>
      </app-card-box>
      <app-card-box class="space-y-1">
        <p class="text-lg font-medium">
          {{ $t("dashboard.account.total_payout_request") }}
        </p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          class="w-10"
          :random-size="true"
        />
        <p class="text-2xl" v-if="!loading">
          {{ summary.total_payout_requested.count }}
        </p>
      </app-card-box>
      <app-card-box class="space-y-1">
        <p class="text-lg font-medium">
          {{ $t("dashboard.account.total_payout_approve") }}
        </p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          class="w-10"
          :random-size="true"
        />
        <p class="text-2xl font-light" v-if="!loading">
          {{ summary.total_payout_approved.count }}
        </p>
      </app-card-box>
    </div>
    <div
      class="grid grid-rows-1 md:grid-cols-4 gap-3 mt-3 text-gray-600"
      v-if="errorMessage == null"
    >
      <app-card-box class="space-y-1 col-span-4">
        <p class="text-lg font-medium">
          {{ $t("dashboard.account.gross_sales") }}
        </p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          class="w-32"
          :random-size="true"
        />
        <p class="text-2xl" v-if="!loading">
          {{ $formats.currency(summary.currency, summary.gross_sales ?? 0) }}
        </p>
      </app-card-box>
    </div>

    <!-- Top 10 Sales Collection  -->
    <app-card-box class="space-y-8 mt-3">
      <p class="text-lg font-medium">
        {{ $t("dashboard.account.top_sales_collection") }}
      </p>
      <div v-if="loading" class="space-y-5">
        <div v-for="index in 5" :key="index">
          <div class="flex justify-between pt-1">
            <paragraph-shimmer
              :is-loading="loading"
              :lines="1"
              class="w-56"
              :random-size="true"
            />
            <paragraph-shimmer
              :is-loading="loading"
              :lines="1"
              class="w-24"
              :random-size="true"
            />
          </div>
          <hr class="w-full border-gray-200 mt-3" />
        </div>
      </div>
      <div v-else>
        <div v-if="!$lodash.isEmpty(summary.top_sale_collection)">
          <div
            v-if="!loading"
            class="grid grid-rows-5 grid-flow-col gap-y-5 gap-x-20"
          >
            <div
              v-for="(top_sale, index) in summary.top_sale_collection"
              :key="top_sale"
            >
              <div class="flex md:flex-row flex-col justify-between">
                <p class="capitalize">
                  {{ ++index + ". " + top_sale.business_name }}
                </p>
                <p class="font-bold">
                  {{
                    $formats.currency(summary.currency, top_sale.total_amount)
                  }}
                </p>
              </div>
              <hr class="w-full border-gray-200 mt-3" />
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="!loading">
            <p class="text-xl text-gray-500">
              {{ $t("general.no_data_found") }}
            </p>
          </div>
        </div>
      </div>
    </app-card-box>
  </div>
</template>

<script>
import { ParagraphShimmer } from "vue3-shimmer";
import moment from "moment";

export default {
  components: {
    ParagraphShimmer,
  },
  computed: {
    summary() {
      return this.$store.getters["dashboardStore/accountSummary"];
    },
    loading() {
      return this.$store.getters["dashboardStore/accountSummaryLoading"];
    },
    errorMessage() {
      return this.$store.getters["dashboardStore/accountSummaryMessage"];
    },
    dashboard_summary_title() {
      switch (this.select_date_type) {
        case this.$t("general.weekly"):
          return this.$t("dashboard.week_summary");
        case this.$t("general.monthly"):
          return this.$t("dashboard.month_summary");
        case this.$t("general.yearly"):
          return this.$t("dashboard.year_summary");
        default:
          return this.$t("dashboard.today_summary");
      }
    },
  },
  data() {
    return {
      select_date_format: null,
      select_date_type: null,
    };
  },
  methods: {
    getSummary() {
      this.$store.dispatch(
        "dashboardStore/retrieveAccountDashboardSummary",
        this.select_date_format
      );
    },
    getToDate() {
      return moment(String(this.select_date?.to), "YYYY-MM-DD")
        .subtract(1, "days")
        .format("DD-MM-YYYY");
    },
    getFromDate() {
      return moment(String(this.select_date?.from), "YYYY-MM-DD")?.format(
        "DD-MM-YYYY"
      );
    },
  },
};
</script>
