<template>
  <div class="space-y-5">
    <div>
      <h1 class="text-2xl font-medium">
        {{ dashboard_summary_title }}
      </h1>
    </div>
    <div
      class="h-40 flex flex-row pt-4 space-x-4 items-center justify-center"
      v-if="errorMessage != null"
    >
      <p class="text-error">{{ errorMessage }}</p>
      <app-button-outline
        class="h-10 space-x-2 w-auto justify-between"
        @click="getSummary"
      >
        <div
          class="place-items-start flex flex-col text-gray-700 font-semibold antialiased text-xs"
        >
          <span class="text-gray-700">
            {{ $t("general.try_again") }}
          </span>
        </div>
      </app-button-outline>
    </div>
    <div
      class="flex flex-col justify-between md:flex-row md:space-y-0 md:space-x-3 space-y-3 space-x-0"
      v-if="errorMessage == null"
    >
      <p
        v-if="this.select_date_type == this.$t('general.daily')"
        class="border p-2 rounded-md"
      >
        {{ getFromDate() }}
      </p>
      <p v-else class="border p-2 rounded-md">
        {{ getFromDate() }} {{ $t("general.to") }} {{ getToDate() }}
      </p>

      <app-button-select-date
        @select_date="select_date = $event"
        @select_date_format="select_date_format = $event"
        @select_date_type="select_date_type = $event"
        @on_select="getSummary"
      />
    </div>
    <div
      class="grid grid-rows-1 md:grid-cols-2 gap-3 mt-3 text-gray-600"
      v-if="errorMessage == null"
    >
      <!-- <app-card-box class="space-y-1 col-span-2 h-40">
        <p class="text-lg font-medium">{{ $t("dashboard.gross_sales") }}</p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          :random-size="true"
        />
        <p class="text-2xl" v-if="!loading">
          {{ $formats.currency(summary.currency, summary.sales) }}
        </p>
      </app-card-box> -->
      <app-card-box class="space-y-1">
        <p class="text-lg font-medium">{{ $t("dashboard.balance") }}</p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          :random-size="true"
        />
        <p class="text-2xl" v-if="!loading">
          {{ $formats.currency(summary.currency, summary.balance > 0 ? summary.balance : 0 ) }}
        </p>
      </app-card-box>
      <app-card-box class="space-y-1">
        <p class="text-lg font-medium">{{ $t("dashboard.payouts") }}</p>
        <paragraph-shimmer
          :is-loading="loading"
          :lines="1"
          :random-size="true"
        />
        <p class="text-2xl font-light" v-if="!loading">
          {{ $formats.currency(summary.currency, summary.payout) }}
        </p>
      </app-card-box>
    </div>
  </div>
</template>

<script>
import { ParagraphShimmer } from "vue3-shimmer";
import moment from "moment";

export default {
  components: {
    ParagraphShimmer,
  },
  data() {
    return {
      select_date_format: null,
      select_date_type: null,
    };
  },
  computed: {
    summary() {
      return this.$store.getters["dashboardStore/summary"];
    },
    loading() {
      return this.$store.getters["dashboardStore/loading"];
    },
    errorMessage() {
      return this.$store.getters["dashboardStore/message"];
    },
    dashboard_summary_title() {
      switch (this.select_date_type) {
        case this.$t("general.weekly"):
          return this.$t("dashboard.week_summary");
        case this.$t("general.monthly"):
          return this.$t("dashboard.month_summary");
        case this.$t("general.yearly"):
          return this.$t("dashboard.year_summary");
        default:
          return this.$t("dashboard.today_summary");
      }
    },
  },
  methods: {
    getSummary() {
      this.$store.dispatch("dashboardStore/retrieveSummaryDashboard", {
        date_between: this.select_date_format,
      });
    },
    getToDate() {
      return moment(String(this.select_date?.to), "YYYY-MM-DD")
        .subtract(1, "days")
        .format("DD-MM-YYYY");
    },
    getFromDate() {
      return moment(String(this.select_date?.from), "YYYY-MM-DD")?.format(
        "DD-MM-YYYY"
      );
    },
  },
};
</script>
